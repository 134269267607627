module.exports = {
  title: 'My Dear Mother',
  tagline: 'She was nine years old when she discovered<br/>that she was not an orphan',
  subtitle: 'A film by Paul-Anders Simma',
  anchors: [
    { link: '#trailer', label: 'Trailer' },
    { link: '#synopsis', label: 'Synopsis' },
    { link: '#backstory', label: 'Backstory' },
    { link: '#crew', label: 'Crew' },
    { link: '#sales', label: 'International sales' },
    { link: '#press', label: 'Press' }
  ],
  videoUrl: 'https://player.vimeo.com/video/337283813',
  facebook: 'https://www.facebook.com/GreetingsfromTasha/',
  instagram: 'https://www.instagram.com/my_dear_mother_movie/',
  synopsis: [
    'Tasha is an eleven-year-old Lapp girl. She is an inmate of a Russian orphanage, even when she is not an orphan. Tasha believes that her mother is dead and that her father is in jail for life. To survive, she has found a Russian stepmother at the orphanage.',
    'One day her biological mother suddenly calls her. Tasha is afraid since the authorities had declared her mother dead by a drug overdose several years ago. Her mother claims that she is now drug-free. Tasha has mixed feelings for this newfound "mother". She is longing to have a mother of her own, but still, she is afraid. At the end of the film, Tasha reaches the most difficult crossroads of her life. She has to make a decision that will have an enormous impact on the rest of her life. Will she become a Sami by choosing to live with her biological mother, or should she become a Russian by choosing a life with the Russian foster mother appointed by the orphanage?',
    'In the background of Tasha\'s fate lurks the greater global narrative about the "stolen generations". Children belonging to different indigenous peoples all over the world were "stolen" and placed in orphanages hoping that they will be assimilated into the majority population. In North America and Australia, the "stolen children" constitute a relatively large population group. For years the authorities in the English-speaking former colonies have been devoting a great deal of effort to finding ways to achieve reconciliation. In Europe, the "stolen generations" remain silent, even though many indigenous parents were doomed as not capable of looking after their children.'
  ],
  backstory: [
    'On the morning of December 29, 1890, the U.S. Army attacked a Lakota Indian camp. Several hundred defenceless non-combatant Villagers where killed, in what was later known as the massacre of Wounded Knee. This "battle" ended the Indian wars. The indigenous resistance movement against European colonialism was finally broken. Sitting Bull and other respected chiefs surrendered and the natives where disarmed and forced into reservations.',
    'Instead, a new silent war started by the U.S. government. It\'s a war against the children of the defeated indigenous peoples. This war is still raging on in boarding schools and orphanages.',
    'Richard H. Pratt, a U.S. Army officer, wrote the policy for the new war: " Kill the Indian, save the man!" Assimilation by force was regarded as a progressive solution to the" Indian problem". The first Indian school was opened in Carlisle, Pennsylvania, in converted U.S. Army barracks. The school was run with military discipline and assimilationist philosophy. Students were clothed in military uniforms, taught English and forbidden to speak their native language. The school was effective, and the warrior spirit of the conquered was crippled forever. Government\'s all over the New World noticed the success and soon similar native assimilation schools where established on all continents. In Australia a national official "Sorry Day" is held on May 28, every year to remember and commemorate the mistreatment of the country\'s indigenous population. The Australian governments\' policies resulted in a "Stolen Generation"—i.e., "Aboriginal children separated, often forcibly, from their families in the interest of turning them into white Australians".',
    'Assimilation schools were formed even in the Old World. Eskimo and Lapp children where sent to boarding schools and orphanages. The native languages were banned. The Lapps are still analphabet\'s in their native language. Instead, they were forced at boarding schools to learn the writings of their colonists.',
    'In the Northern part of Russia and Siberia, large numbers of different indigenous peoples inhabit areas that are rich in natural resources. Valuable minerals, oil, and other expensive natural resources are hidden under the ground. In 1950 the Russian Lapps were moved from traditional villages into towns, to make a place for Socialism — their children where sent to orphanages and boarding schools to be Russified.',
    'Tasha, the main person in our film, was brought in to the Russian orphanage when she was four years old. Since then, she has not spoken her native language, even if there are many other Lapp children at the orphanage. The Lappish language is not officially forbidden, but never used. The orphanage has appointed a Russian stepmother for Tasha, even if her biological parents are claiming her back. In the film, we will follow the forced assimilation process of Tasha and the struggle of her biological parents to requesting her back.',
    '<span class="my-6 font-roboto-condensed text-xl uppercase">Want to help the girls?</span>',
    'Here is how you can support the girls from the movie "My Dear Mother" in their struggle for education and a better future. You don\'t have to donate large sums. Instead, this is the way to show the girls that there are many good people on the planet, unknown friends everywhere, who care and who want to stretch out a symbolic helping hand.'
  ],
  pictures: [
    '2. kanin_Tasha.jpg',
    '3. bw-tasha_orpanage_kids.jpg',
    '4. noplace for a kid on the tundra.jpg',
    '5.tasha_pello.jpg',
    '6.Tasha_2017.jpg',
    '8. Tashas ancestors.jpg'
  ],
  crew: [
    {
      name: 'Paul Simma',
      job: 'Director',
      picture: '11. psimma_photo.jpg',
      text: 'Paul has 30 years of experience as a Director, Producer, Manager and Commissioning Editor in the Nordic countries. Paul directed and produced "To Kill A Reindeer", a 58\' documentary, in 2012. His latest documentary film "Olga - To My Friends" was premiered at IDFA in 2013 awarded the Director\'s Prize at the Al Jazeera festival in 2014.'
    },
    {
      name: 'Elen Lotman',
      job: 'Director of photography',
      picture: 'Ellen_Lotman_ESC_415-bc642.jpg',
      text: 'Elen has been the cinematographer on over 15 documentary and feature films in Estonia. She is also the headmaster of the Baltic Film School in Tallinn.'
    },
    {
      name: 'Martti Turunen',
      job: 'Sound designer',
      picture: 'martti 1210.png',
      text: 'Martti has great experience recording and designing soundtracks for documentaries, T.V. series and feature films.'
    },
    {
      name: 'Jari Innanen',
      job: 'Editor',
      picture: 'Jari-Innanen.png',
      text: 'Film editor and sound designer. In his editing carrier, he has mainly edited documentary films.'
    },
    {
      name: 'Matti Bye',
      job: '',
      picture: 'matti.png',
      text: 'A pianist and composer. He has composed music for over 30 films and T.V. series as well as additional scores for Theatre and Dance pieces. In 2014, he was nominated twice at Sweden\'s Guldbagge Awards in which his soundtrack for the film Faro won the award. He also won a Gudbagge in 2009 for his score for the film Everlasting Moments.'
    }
  ],
  sales: [
    '<strong>Ab Saamifilmi oy</strong>',
    'FIN-99490 KILPISJÄRV',
    'Lapland, Finland',
    '+358 (9) 315 77 167',
    '<a href="mailto:s@safi.nu">s@safi.nu</a>'
  ],
  press: [
    'Download high resolution photos <a href="press.zip" class="uppercase lg:hover:glow font-bold">HERE</a>'
  ],
  logos: [
    'safi',
    'logo2',
    'svt',
    'finnish_film',
    'nordnorsk',
    'spare bank',
    'fond_for_lyd_og_bild',
    'err',
    'media_programme',
    'yle',
    'avek'
  ]
}
