// @flow

import React, { useState, useRef } from 'react'
import Observer from '@researchgate/react-intersection-observer'

import useImage from '@hooks/useImage'

interface ImageInterface {
  src: string,
  style?: Object,
  fit?: string,
  imgClassName?: string,
  className?: string
}

export default function Image ({ src, style, fit = 'cover', imgStyle, imgClassName, className }: ImageInterface) {
  const image = useImage(src)

  if (!image) {
    return null
  }

  const imgRef = useRef(null)
  const [stateSizes, setStateSizes] = useState(1)
  const [isBlurred, setIsBlurred] = useState(true)

  const setBlur = () => {
    if (!imgRef.current.currentSrc.startsWith('data:image')) {
      setIsBlurred(false)
    }
  }

  const setSizes = (state) => {
    if (stateSizes <= 1 && state.isIntersecting) {
      setStateSizes(Math.ceil(imgRef.current.getBoundingClientRect().width / window.innerWidth * 100))
    }
  }

  const imgStyles = {
    height: '100%',
    width: '100%',
    clipPath: 'inset(0)',
    filter: isBlurred ? 'blur(12px)' : 'none',
    transform: 'translate3d(0,0,0)',
    objectFit: fit,
    ...imgStyle
  }

  return (
    <Observer onChange={(e) => setSizes(e)} disabled={stateSizes > 1}>
      <picture style={style} className={className}>
        {image.fluid.srcWebp && (
          <source type="image/webp"
            srcSet={`${image.fluid.base64} 32w, ${image.fluid.srcSetWebp}`}
            sizes={`${stateSizes}vw`}/>
        )}

        <source srcSet={`${image.fluid.base64} 32w, ${image.fluid.srcSet}`}
          sizes={`${stateSizes}vw`}/>

        <img
          ref={imgRef}
          src={image.fluid.src}
          alt=""
          style={imgStyles}
          onLoad={setBlur}
          className={imgClassName}
        />
      </picture>
    </Observer>
  )
}
