import React, { useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import content from '../../../content/content'

export default function Nav () {
  const [expand, setExpand] = useState(false)

  return (
    <nav className="flex items-center justify-between flex-wrap p-1 fixed top-0 w-full z-20 bg-brand-lighter lg:hidden">
      <div className="flex w-full">
        <button
          onClick={() => setExpand(!expand)}
          className="ml-auto flex items-center px-3 focus:outline-none text-white">
          {expand && (
            <svg className="h-12 w-12 svg-glow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"/>
              <line x1="6" y1="6" x2="18" y2="18"/>
            </svg>
          )}
          {!expand && (
            <svg className="h-12 w-12 svg-glow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
              <line x1="1" y1="8" x2="21" y2="8"/>
              <line x1="1" y1="12" x2="21" y2="12"/>
              <line x1="1" y1="16" x2="21" y2="16"/>
            </svg>
          )}
        </button>
      </div>

      <div className={`w-full block flex-grow ${!expand && 'hidden'}`}>
        <div className={`${!expand && 'hidden'} uppercase glow-lg text-white font-bold text-lg`}>
          {content.anchors.map((anchor, index) => (
            <div key={index} className="mb-6 ml-4">
              <AnchorLink offset='80' onClick={() => setExpand(!expand)} href={anchor.link}>{anchor.label}</AnchorLink>
            </div>
          ))}

          <div className="flex mt-8 mb-16 ml-2">
            <a href={content.facebook} target="_blank" rel="noopener noreferrer" className="h-6 w-6 box-glow bg-white rounded-full text-indigo-800 p-1 mx-2">
              <svg className="h-full w-full" stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 10h3v3h-3v7h-3v-7h-3v-3h3v-1.255c0-1.189.374-2.691 1.118-3.512.744-.823 1.673-1.233 2.786-1.233h2.096v3h-2.1c-.498 0-.9.402-.9.899v2.101z"/>
              </svg>
            </a>
            <a href={content.instagram} target="_blank" rel="noopener noreferrer" className="h-6 w-6 box-glow bg-white rounded-full text-indigo-800 p-1 mx-2">
              <svg className="h-full w-full" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"/>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/>
                <line x1="17.5" y1="6.5" x2="17.5" y2="6.5"/>
              </svg>
            </a>
          </div>

        </div>
      </div>
    </nav>
  )
}
