/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import '../tailwind.css'
import content from '../../../content/content'

import Nav from '@components/Nav'
import Image from '@components/Image'
import Logos from '@components/Logos'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [parallax, setParallax] = useState(0)
  const [scrollToTop, setScrollToTop] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => scrollEvent())
    return window.removeEventListener('scroll', () => scrollEvent())
  }, [])

  const scrollEvent = () => {
    const value = window.pageYOffset * 0.3
    if (value < 300) {
      setParallax(value)
    }

    if (window.pageYOffset > 500) {
      setScrollToTop(true)
    }
    if (window.pageYOffset < 500) {
      setScrollToTop(false)
    }
  }

  return (
    <>
      <Nav/>
      <main className="bg-brand text-white pt-4 lg:pt-0 overflow-x-hidden font-roboto">
        {scrollToTop && (
          <div className="fixed z-20" style={{ bottom: 25, right: 25 }}>
            <svg onClick={() => window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })} className="h-8 w-8 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="12" y1="19" x2="12" y2="5"/>
              <polyline points="5 12 12 5 19 12"/>
            </svg>
          </div>
        )}


        <div className="container mx-auto relative px-4">
          <div className="absolute" style={{
            right: -50,
            left: -50,
            top: 0,
            transform: `translate3d(0, ${parallax}px, 0)`,
            transition: 'transform 5ms cubic-bezier(0.1, 0.7, 1.0, 0.1)'
          }}>
            <Image src="bg.png" imgClassName="pt-10 lg:pt-0"/>
          </div>

          <div className="mx-auto lg:px-16 xl:px-32 lg:py-6 relative z-10">
            {children}
          </div>
        </div>
        <Logos content={content}/>
      </main>

    </>
  )
}

export default Layout
