import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export default function useImage (filename) {
  const data = useStaticQuery(graphql`
  query {
    images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
  `)

  const image = data.images.edges.find(n => n.node.relativePath.includes(filename))

  return image ? image.node.childImageSharp : null
}
