// @flow

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export default function Logos ({ content }: { content: any }) {
  const allLogos = useStaticQuery(graphql`
  query {
    images: allFile(filter: {extension: {regex: "/(svg)/"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
  `).images.edges.map(image => image.node.publicURL)

  const logos = content.logos.map(image => {
    return allLogos.find(img => img.split('/').pop().startsWith(image))
  })

  return (
    <div className="container mx-auto px-2">
      <section className="row my-16">
        {logos.map(src => (
          <div key={src} className="col w-1/4 mb-6 lg:flex-1 flex">
            <img className="px-2 lg:px-0 m-auto h-10" src={src} alt=""/>
          </div>
        ))}
      </section>
    </div>
  )
}
